import {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import Intro from './components/Intro';
import MainProfile from "./components/MainProfile";
import {APP_STATES, SUPPORTED_ROUTES} from "./utils";

const App  = () =>  {
  const [appState, setAppState] = useState(APP_STATES.INTRO)
  const location = useLocation();
  useEffect(() => {
    // Supported routes, bypass intro.
    if (SUPPORTED_ROUTES.includes(location.pathname)) {
      setAppState(APP_STATES.MAIN_PROFILE)
    }
  }, [location]);

  const ProfileContent = () => {
    switch (appState) {
      case APP_STATES.MAIN_PROFILE:
        return (
          <MainProfile />
        )
      case APP_STATES.INTRO:
      default:
        return <Intro setAppState={setAppState}/>
    }
  }

  return (
    <div className="App">
      <ProfileContent />
    </div>
  );
}

export default App;
