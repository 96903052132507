export const APP_STATES = {
	INTRO: 0,
	MAIN_PROFILE: 1
};

export const POP_OVER_STATE = {
	NONE: 0,
	RESUME: 1,
	PROJECTS: 2,
	CONTACT: 3,
	CHECKOUT: 4
};

export const SUPPORTED_ROUTES = [
	'/coffee',
	'/resume',
	'/contact',
	'/projects'
]