import React, { useCallback } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51N3LWRJ5BX7PgfOzdxTvCc0MU3Nqk3dgJRYVNp6dmh9QNAa7rb4iZZUtOrxUXMzJakES2c1Y3wjvUqrI26A5rpTL00h7Ztwpun');

const Checkout = () => {
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return fetch("https://y3p.io:4242/create-checkout-session", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => data.client_secret);
  }, []);

  const options = {fetchClientSecret};

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export default Checkout;