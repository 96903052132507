import React from 'react';
import '../styles/PopoverContent.css'
import { motion } from 'framer-motion'
import { POP_OVER_STATE } from '../utils';
import { IoMdCloseCircle } from "react-icons/io";
import Resume from '../components/Resume';
import Projects from './Projects';
import Contact from './Contact';
import Checkout from './Checkout';
import {useLocation, useNavigate} from 'react-router-dom';

const PopoverContent = ({ popoverState, setPopoverState }) => {

  /*
  * Indices follow:
  * export const POP_OVER_STATE = {
      NONE: 0,
      RESUME: 1,
      PROJECTS: 2,
      CONTACT: 3,
      CHECKOUT: 4
    };
  * */
  const navigate = useNavigate();
  const location = useLocation();

  const popoverContent = [
    null,
    <Resume/>,
    <Projects/>,
    <Contact/>,
    <Checkout/>
  ];

  return (
    <motion.div
      className='popover-container'
      animate={{
        visibility: 'visible',
        opacity: 1
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IoMdCloseCircle
        className='popover-close-btn'
        onClick={() => {
          // Meaning they were directly brought to this path. Reset to / to get main profile intro experience.
          if (location.pathname !== '/') {
            navigate('/', { replace: true, });
          }
          setPopoverState(POP_OVER_STATE.NONE)
        }}
      />
      <div className='popover-content'>
        {popoverContent[popoverState]}
      </div>
    </motion.div>
  )
};

export default PopoverContent;